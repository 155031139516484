import {Component, Input} from '@angular/core';
import {SharedModule} from '@app/shared/shared.module';
import {UserCapability} from '@generated/models';
import {MenuDrawerComponent} from './menu-drawer.component';
import {UserMenuItemsComponent} from './user-menu-items.component';
import {UserMenuNavItemsComponent} from './user-menu-nav-items.component';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    SharedModule,
    MenuDrawerComponent,
    UserMenuNavItemsComponent,
    UserMenuItemsComponent,
  ],
  template: `
    <div class="flex justify-end">
      <div class="md:basis-1/2 flex md:justify-around items-center">
        <app-user-menu-nav-items />
      </div>
      <div class="flex items-center justify-end md:basis-1/2">
        <app-menu-drawer>
          <div class="flex flex-col gap-6">
            <div class="block md:hidden">
              <app-user-menu-nav-items direction="col" />
            </div>
            <div>
              <app-user-menu-items [capabilities]="capabilities" />
            </div>
          </div>
        </app-menu-drawer>
      </div>
    </div>
  `,
})
export class UserMenuComponent {
  @Input() capabilities: UserCapability[] = [];
}
