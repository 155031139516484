import {createSelector} from '@ngrx/store';
import {CORE_SELECTOR} from '../core.store';
import {layoutStateKey} from '../reducers/layout.reducer';

export const selectLayoutState = createSelector(
  CORE_SELECTOR,
  (state) => state[layoutStateKey],
);

export const selectToast = createSelector(
  selectLayoutState,
  (state) => state.toast,
);
