import {inject} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {filter, switchMap} from 'rxjs';
import {ProcessTransfer, UploadCompleted} from '../../actions/upload.actions';
import {selectChecksumFiles} from '../../selectors/upload.selector';

export const uploadCompleted = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(UploadCompleted),
      concatLatestFrom(() => [
        store.select(selectChecksumFiles).pipe(filter((f) => f !== null)),
      ]),
      switchMap(([action, checksumFiles]) => [
        ProcessTransfer({transferId: action.payload.id, checksumFiles}),
      ]),
    );
  },
  {functional: true},
);
