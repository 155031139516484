import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export const unauthorizedInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const router = inject(Router);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || error.toString().includes('401')) {
        router.navigate(['/', 'account', 'logout']);
        return throwError(() => error);
      }
      if (error.status === 403 || error.toString().includes('403')) {
        return throwError(() => error);
      }
      if (error.status === 400 || error.toString().includes('400')) {
        return throwError(() => error);
      }

      router.navigate(['/', 'error']);
      return throwError(() => error);
    }),
  );
};
