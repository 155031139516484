import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ExternalUrlPipe} from '@app/shared/pipes/external-url.pipe';
import pck from '../../../../../../package.json';

@Component({
  selector: 'app-footer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ExternalUrlPipe],
  template: `
    <div class="relative h-0">
      <div
        class="absolute bottom-0 h-14 w-full text-black text-2xs font-medium flex justify-center py-4"
      >
        <div class="flex items-center justify-center">
          <a class="underline" [href]="'credits' | externalUrl: 'site'" i18n
            >Credits</a
          >
          <span>&nbsp;&bullet;&nbsp;</span>
          <a class="underline" [href]="'privacy' | externalUrl: 'site'" i18n
            >Privacy</a
          >
          <span>&nbsp;&bullet;&nbsp;</span>
          <a
            class="underline"
            [href]="'termini-e-condizioni-di-utilizzo' | externalUrl: 'site'"
            i18n
            >Terms</a
          >
        </div>
      </div>
    </div>
  `,
})
export class FooterComponent {
  readonly release: string = pck.version;
}
