import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {User, UserCapability} from '@generated/models';
import {Store} from '@ngrx/store';
import {combineLatest, map, Observable, shareReplay} from 'rxjs';
import {AmplitudeService} from './core/amplitude/amplitude.service';
import {ChatwootService} from './core/chatwoot/chatwoot.service';
import {CookieYesService} from './core/cookie-yes/cookie-yes.service';
import {IamWithNgZorroModule} from './core/iam-with-ngzorro/iam-with-ngzorro.module';
import {MetaService} from './core/services/meta.service';
import {ShowToast} from './core/store/actions/layout.action';
import {selectUser} from './core/store/selectors/user.selector';
import {ToastStripeOnboardSuccessComponent} from './shared/components/toast/toast-stripe-onboard-success.component';
import {ONBOARDING_SUCCESS_PARAM} from './shared/constants';
import {hasCapability} from './shared/functions/has-capability';

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, IamWithNgZorroModule],
  template: `<router-outlet /><iam-with-ngzorro-content></iam-with-ngzorro-content> `,
})
export class AppComponent {
  private readonly store: Store = inject(Store);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly amplitude: AmplitudeService = inject(AmplitudeService);
  private readonly metaService: MetaService = inject(MetaService);
  private readonly titleService: Title = inject(Title);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly cookieYesService: CookieYesService =
    inject(CookieYesService);
  private readonly chatwootService: ChatwootService = inject(ChatwootService);

  private queryParams$ = this.route.queryParams;
  private user$: Observable<User | null> = this.store.select(selectUser);
  private canAddPaidTransfer$: Observable<boolean> = this.user$.pipe(
    map((user) =>
      hasCapability(user?.capabilities, UserCapability.CanAddPaidTransfer),
    ),
    shareReplay(),
  );

  constructor() {
    this.titleService.setTitle(
      $localize`Fulltransfer - Earn from sharing your file.`,
    );
    this.metaService.updateMetaTag();
    this.cookieYesService.init();
    this.chatwootService.load();
    this.#watchStripeOnboarding();
  }

  #watchStripeOnboarding(): void {
    combineLatest({
      queryParams: this.queryParams$,
      canAddPaidTransfer: this.canAddPaidTransfer$,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({queryParams, canAddPaidTransfer}) => {
        if (queryParams[ONBOARDING_SUCCESS_PARAM]) {
          canAddPaidTransfer && this.#showStripeOnboardingSuccess();
          this.#removeQueryParams();
        }
      });
  }

  #showStripeOnboardingSuccess(): void {
    this.store.dispatch(
      ShowToast({
        payload: {
          component: ToastStripeOnboardSuccessComponent.name,
        },
      }),
    );
  }

  #removeQueryParams() {
    this.router.navigate([], {
      replaceUrl: true,
    });
  }
}
