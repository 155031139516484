import {Component, Input} from '@angular/core';
import {LockOutline} from '@ant-design/icons-angular/icons';
import {iconProvider} from '@app/shared/icon.module';
import {HasCapabilityPipe} from '@app/shared/pipes/has-capability.pipe';
import {SharedModule} from '@app/shared/shared.module';
import {UserCapability} from '@generated/models';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {FtLogoutOutline} from '@icons/FtLogoutOutline';
import {FtSettingOutline} from '@icons/FtSettingOutline';
import {FtTagPriceOutline} from '@icons/FtTagPriceOutline';
import {FtUserAddOutline} from '@icons/FtUserAddOutline';
import {MenuItem, MenuItemComponent} from './menu-item.component';
import {externalUrl} from '@app/shared/functions/external-url';

@Component({
  selector: 'app-user-menu-items',
  standalone: true,
  imports: [
    SharedModule,
    HasCapabilityPipe,
    NzDividerModule,
    MenuItemComponent,
  ],
  providers: [
    iconProvider([
      FtSettingOutline,
      FtLogoutOutline,
      FtTagPriceOutline,
      FtUserAddOutline,
      LockOutline,
    ]),
  ],
  template: `
    <div class="flex flex-col gap-6">
      @for (item of items.slice(0, -1); track $index) {
        <app-menu-item [item]="item" />
      }
      @if (capabilities | hasCapability: UserCapability.CanAccessAdminArea) {
        @for (item of adminItems; track $index) {
          <app-menu-item [item]="item" />
        }
      }
      <nz-divider class="!m-0 bg-white" />
      @for (item of items.slice(-1); track $index) {
        <app-menu-item [item]="item" />
      }
    </div>
  `,
})
export class UserMenuItemsComponent {
  readonly UserCapability = UserCapability;
  @Input() capabilities: UserCapability[] = [];

  readonly items: MenuItem[] = [
    {
      label: $localize`Pricing`,
      url: externalUrl('pricing', 'site'),
      nzIcon: 'ft-tag-price',
      color: 'white',
    },
    {
      label: $localize`Settings`,
      url: ['/', 'restricted-area', 'settings'],
      nzIcon: 'ft-setting',
      color: 'gray',
    },
    {
      label: $localize`Referral`,
      url: ['/', 'restricted-area', 'referral'],
      nzIcon: 'ft-user-add',
      color: 'lightblue',
    },
    {
      label: $localize`Logout`,
      url: ['/', 'account', 'logout'],
      nzIcon: 'ft-logout',
      size: 'small',
      color: 'white',
    },
  ];

  readonly adminItems: MenuItem[] = [
    {
      label: $localize`Admin Area`,
      url: ['/', 'admin-area'],
      nzIcon: 'lock',
      color: 'white',
    },
  ];
}
