import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  InjectionToken,
  Input,
  Output,
  Type,
  inject,
} from '@angular/core';
import {CloseOutline} from '@ant-design/icons-angular/icons';
import {Toast} from '@app/core/store/interfaces/layout-state.interface';
import {SharedModule} from '@app/shared/shared.module';
import {iconProvider} from '../../icon.module';
import {ToastStripeOnboardSuccessComponent} from './toast-stripe-onboard-success.component';

export const TOAST_COMPONENTS = new InjectionToken<Type<any>[]>(
  'TOAST_COMPONENTS',
);
@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (visible) {
      <section class="bg-white border-t border-t-black">
        <div class="flex items-center relative">
          <div class="py-3 md:pr-12 pr-10 flex-1 text-2xs font-medium">
            @if (component) {
              <ng-container
                *ngComponentOutlet="component; inputs: componentInputs"
              />
            } @else {
              {{ text }}
            }
          </div>
          <div class="w-12 text-right absolute md:right-0 -right-2">
            @if (closable) {
              <button nz-button nzType="link" (click)="onClose()">
                <span nz-icon nzType="close"></span>
              </button>
            }
          </div>
        </div>
      </section>
    }
  `,
  providers: [
    iconProvider([CloseOutline]),
    {
      provide: TOAST_COMPONENTS,
      useValue: [ToastStripeOnboardSuccessComponent],
    },
  ],
})
export class ToastComponent {
  private readonly components = inject(TOAST_COMPONENTS).reduce(
    (map: Record<string, Type<any>>, current: Type<any>) => {
      map[current.name] = current;
      return map;
    },
    {},
  );

  componentInputs = {};
  component: Type<any> | undefined;
  text: string = '';
  closable: boolean = true;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  visible: boolean = false;
  @Input({required: true}) set data(value: Toast | null) {
    this.visible = !!value;
    this.component = value?.component
      ? this.components[value.component]
      : undefined;
    this.text = value?.text ?? '';
    this.closable = value?.closable !== false;
    this.componentInputs = value?.meta ?? {};
  }

  onClose(): void {
    this.data = null;
    this.close.emit();
  }
}
