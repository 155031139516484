import {Component, Input} from '@angular/core';
import {iconProvider} from '@app/shared/icon.module';
import {SharedModule} from '@app/shared/shared.module';
import {FtDownSquareOutline} from '@icons/FtDownSquareOutline';
import {FtSendOutline} from '@icons/FtSendOutline';
import {FtShopOutline} from '@icons/FtShopOutline';
import {MenuItem, MenuItemComponent} from './menu-item.component';

@Component({
  selector: 'app-user-menu-nav-items',
  standalone: true,
  imports: [SharedModule, MenuItemComponent],
  providers: [
    iconProvider([FtDownSquareOutline, FtShopOutline, FtSendOutline]),
  ],
  template: `
    @if (direction === 'col') {
      <div class="flex flex-col gap-6">
        @for (item of items; track $index) {
          <app-menu-item [item]="item" />
        }
      </div>
    } @else {
      <div>
        <ul class="flex items-center gap-2 md:gap-4 m-0 md:pr-0 pr-3">
          @for (item of items; track $index) {
            <li class="relative">
              <a
                nz-button
                nzType="text"
                routerLinkActive
                #liActive="routerLinkActive"
                [routerLink]="item.url"
                class="!text-white !flex !pb-0 !px-2.5 items-center"
              >
                <span
                  nz-icon
                  [nzType]="item.nzIcon"
                  class="text-xl !leading-none !block"
                ></span
                ><span class="!hidden md:!block">{{ item.label }}</span>
              </a>
              @if (liActive.isActive) {
                <div class="absolute w-full -bottom-1">
                  <div
                    class="rounded-full h-2 w-full max-w-6 md:max-w-9 m-auto bottom-10"
                    [ngClass]="{
                      'bg-pink': item.color === 'pink',
                      'bg-blue': item.color === 'blue',
                      'bg-sky': item.color === 'sky',
                    }"
                  ></div>
                </div>
              }
            </li>
          }
        </ul>
      </div>
    }
  `,
})
export class UserMenuNavItemsComponent {
  @Input() direction: 'col' | 'row' = 'row';

  readonly items: MenuItem[] = [
    {
      label: $localize`Transfers`,
      url: ['/', 'restricted-area', 'transfers'],
      nzIcon: 'ft-send',
      color: 'pink',
    },
    {
      label: $localize`Downloads`,
      url: ['/', 'restricted-area', 'downloads'],
      nzIcon: 'ft-down-square',
      color: 'sky',
    },
    {
      label: $localize`Your shop`,
      url: ['/', 'restricted-area', 'shop'],
      nzIcon: 'ft-shop',
      color: 'blue',
    },
  ];
}
