import {inject} from '@angular/core';
import {TransferFileIntegrity} from '@generated/models';
import {TransferService} from '@generated/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NzMessageService} from 'ng-zorro-antd/message';
import {catchError, switchMap, throwError} from 'rxjs';
import {Go} from '../../actions/router.action';
import {
  ProcessTransfer,
  UploadItemsSuccess,
} from '../../actions/upload.actions';

// TODO rimuovere nzmessageservice
export const processTransfer = createEffect(
  (
    actions$ = inject(Actions),
    transferService = inject(TransferService),
    nzMessageService = inject(NzMessageService),
  ) => {
    return actions$.pipe(
      ofType(ProcessTransfer),
      switchMap((action) => {
        const filesIntegrity: TransferFileIntegrity[] = Object.entries(
          action.checksumFiles,
        ).map(([key, value]) => ({
          uploadId: key,
          checksum: value,
        }));

        return transferService
          .processTransfer({
            transferId: action.transferId,
            body: {
              filesIntegrity,
            },
          })
          .pipe(
            switchMap((res) => {
              return [
                UploadItemsSuccess({payload: res}),
                Go({
                  payload: {
                    path: ['/', 'upload', 'complete'],
                    extras: {
                      replaceUrl: true,
                    },
                  },
                }),
              ];
            }),
            catchError((error) => {
              nzMessageService.error(
                $localize`Oh-oh, unable to load all items.`,
              );
              return throwError(() => error);
            }),
          );
      }),
    );
  },
  {functional: true},
);
