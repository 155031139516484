import {Injectable, LOCALE_ID, inject} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {externalUrl} from '@app/shared/functions/external-url';
import {LANGUAGE} from '../constants';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private readonly meta = inject(Meta);
  private readonly language = inject(LANGUAGE);
  private readonly locale = inject(LOCALE_ID);

  updateMetaTag() {
    this.meta.updateTag({
      name: 'description',
      content: $localize`Send your files easily and start earning with Fulltransfer.`,
    });
    this.meta.updateTag({
      property: 'og:url',
      content: 'https://fulltransfer.io',
    });
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({
      property: 'og:title',
      content: $localize`The easiest way to earn just sending your files to anyone - Fulltransfer.`,
    });
    this.meta.updateTag({
      property: 'og:description',
      content: $localize`Send your files easily and start earning with Fulltransfer.`,
    });

    // Locale
    this.meta.updateTag({property: 'og:locale', content: this.locale});

    // Og Image
    const og1Image = externalUrl('assets/img/og1.jpg', 'self', this.language);
    this.meta.updateTag({property: 'og:image', content: og1Image});
    this.meta.updateTag({
      property: 'og:image:alt',
      content: $localize`Fulltransfer, earn from sharing your files`,
    });

    // Twitter
    this.meta.updateTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.updateTag({
      property: 'twitter:domain',
      content: 'fulltransfer.io',
    });
    this.meta.updateTag({
      property: 'twitter:url',
      content: 'https://fulltransfer.io',
    });
    this.meta.updateTag({
      name: 'twitter:title',
      content: $localize`Fulltransfer - Earn from sharing your file.`,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: $localize`The platform that empowers you to monetize your digital content simply by sharing it.`,
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: externalUrl('assets/img/og2.jpg', 'self', this.language),
    });
  }
}
