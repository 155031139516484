import {inject} from '@angular/core';
import {ChatwootService} from '@app/core/chatwoot/chatwoot.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {filter, switchMap, take, tap} from 'rxjs';
import {ToggleChatwootReady} from '../../actions/chatwoot.action';
import {selectChatwootReady} from '../../selectors/chatwoot.selector';

export const ToggleChatwootReadyEffect = createEffect(
  (
    actions$ = inject(Actions),
    chatwootService = inject(ChatwootService),
    store = inject(Store),
  ) => {
    return actions$.pipe(
      ofType(ToggleChatwootReady),
      switchMap((action) =>
        store.select(selectChatwootReady).pipe(
          filter((chatwootReady) => chatwootReady !== null),
          take(1),
          tap((chatwootReady) => {
            if (chatwootReady) {
              chatwootService.toggleBubbleVisibility(action.show);
            }
          }),
        ),
      ),
    );
  },
  {functional: true, dispatch: false},
);
