import {Component, Input} from '@angular/core';
import {HasCapabilityPipe} from '@app/shared/pipes/has-capability.pipe';
import {SharedModule} from '@app/shared/shared.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';

export type MenuItem = {
  label: string;
  url: string[] | null | string;
  nzIcon: string;
  size?: 'base' | 'small';
  color?: 'blue' | 'pink' | 'lightblue' | 'gray' | 'white' | 'sky';
};
@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [SharedModule, HasCapabilityPipe, NzDividerModule],
  template: `
    @if (isExternal) {
      <a
        [href]="item?.url"
        class="flex !text-white items-center gap-4 justify-between font-semibold h-10 min-w-52 w-full"
        ><span>{{ item?.label }}</span
        ><span nz-icon [nzType]="item?.nzIcon ?? ''" class="text-2xl"></span
      ></a>
    } @else {
      <a
        [routerLink]="item?.url"
        routerLinkActive
        #liActive="routerLinkActive"
        [ngClass]="{
          'flex-row-reverse justify-end': item?.size === 'small',
          'justify-between': item?.size !== 'small',
        }"
        class="flex items-center gap-2 h-10 min-w-52 w-full !text-white"
      >
        <div class="flex gap-4 items-center">
          @if (liActive.isActive) {
            <span
              class="absolute left-0 w-2 h-10"
              [ngClass]="{
                'bg-white': item?.color === 'white',
                'bg-blue': item?.color === 'blue',
                'bg-lightblue': item?.color === 'lightblue',
                'bg-pink': item?.color === 'pink',
                'bg-gray': item?.color === 'gray',
                'bg-sky': item?.color === 'sky',
              }"
            ></span>
          }
          <span
            [ngClass]="{
              'font-semibold': item?.size !== 'small',
              'font-medium text-sm': item?.size === 'small',
            }"
            >{{ item?.label }}</span
          >
        </div>
        <span nz-icon [nzType]="item?.nzIcon ?? ''" class="text-2xl"></span
      ></a>
    }
  `,
})
export class MenuItemComponent {
  isExternal = false;
  item: MenuItem | null = null;
  @Input({required: true, alias: 'item'}) set _item(item: MenuItem | null) {
    this.item = item;
    this.isExternal = !Array.isArray(item?.url);
  }
}
