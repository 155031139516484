import {Component, Input} from '@angular/core';
import {
  QuestionCircleOutline,
  ShopOutline,
} from '@ant-design/icons-angular/icons';
import {externalUrl} from '@app/shared/functions/external-url';
import {iconProvider} from '@app/shared/icon.module';
import {ExternalUrlPipe} from '@app/shared/pipes/external-url.pipe';
import {SharedModule} from '@app/shared/shared.module';
import {FtTagPriceOutline} from '@icons/FtTagPriceOutline';
import {MenuItem, MenuItemComponent} from './menu-item.component';

@Component({
  selector: 'app-guest-menu-items',
  standalone: true,
  imports: [SharedModule, MenuItemComponent, ExternalUrlPipe],
  providers: [
    iconProvider([FtTagPriceOutline, QuestionCircleOutline, ShopOutline]),
  ],
  template: `
    @if (direction === 'col') {
      <div class="flex flex-col gap-6">
        @for (item of items; track $index) {
          <app-menu-item [item]="item" />
        }
      </div>
    } @else {
      <div class="flex gap-4">
        @for (item of items; track $index) {
          <a
            class="!text-white !font-medium"
            [href]="item.url"
            nz-button
            nzType="text"
          >
            <span>{{ item.label }}</span>
          </a>
        }
      </div>
    }
  `,
})
export class GuestMenuItemsComponent {
  @Input() direction: 'col' | 'row' = 'row';

  readonly items: MenuItem[] = [
    {
      label: $localize`Pricing`,
      url: externalUrl(`pricing`, 'site'),
      nzIcon: 'ft-tag-price',
    },
    {
      label: $localize`Shop`,
      url: externalUrl(`shop`, 'site'),
      nzIcon: 'shop',
    },
    {
      label: $localize`Help`,
      url: externalUrl(``, 'site'),
      nzIcon: 'question-circle',
    },
  ];
}
