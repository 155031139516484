import {inject} from '@angular/core';
import {UppyServices} from '@app/core/services/uppy.services';
import {computeFileChecksum} from '@app/core/workers/file-checksum/file-checksum.main';
import {UppyItem} from '@app/shared/uppy';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, map, of, switchMap, throwError} from 'rxjs';
import {
  GenerateChecksum,
  GenerateChecksumFailed,
  GenerateChecksumSuccess,
} from '../../actions/upload.actions';
import {selectConfigurations} from '../../selectors/configurations.selector';

export const generateChecksum = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    uppyServices = inject(UppyServices),
  ) => {
    return actions$.pipe(
      ofType(GenerateChecksum),
      concatLatestFrom(() => [store.select(selectConfigurations)]),
      switchMap(([action, config]) => {
        if (!action.files || !action.files.length) {
          return of({});
        }

        const files: Record<string, File> = action.files.reduce(
          (acc: Record<string, File>, f: UppyItem) => {
            const file = uppyServices.getFileByUploadId(
              uppyServices.instance(f.instance),
              f.uploadId,
            );

            acc[f.uploadId] = file.data as File;
            return acc;
          },
          {},
        );

        const chunkSize = config?.tus.find(
          (t) => t.id === action.files[0].volume,
        )?.chunkSize;

        if (!chunkSize) {
          return throwError(() => new Error('missing chunkSize'));
        }

        return computeFileChecksum(files, chunkSize);
      }),
      map((files) => {
        return GenerateChecksumSuccess({files: files});
      }),
      catchError(() => [GenerateChecksumFailed()]),
    );
  },
  {functional: true},
);
