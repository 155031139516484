import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-toast-stripe-onboard-success',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule],
  template: `
    <div class="flex items-center justify-between gap-4">
      <div class="text-2xs">
        <span i18n
          >Congratulation! You're successfully connected with Stripe!</span
        >
      </div>
      <div class="min-w-16 h-8">
        <img
          src="./assets/img/stripe-blue.svg"
          class="w-full h-full object-contain object-center"
        />
      </div>
    </div>
  `,
})
export class ToastStripeOnboardSuccessComponent {}
