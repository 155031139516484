import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {Store} from '@ngrx/store';
import {
  selectSessionExists,
  selectUser,
  selectUserLoading,
} from '../../core/store/selectors/user.selector';
import {MainLayoutData} from '../interfaces/main-layout-data.interface';
import {selectToast} from '@app/core/store/selectors/layout.selector';

export const mainLayoutResolver: ResolveFn<MainLayoutData> = () => {
  const store = inject(Store);
  return {
    user$: store.select(selectUser),
    sessionExists$: store.select(selectSessionExists),
    loading$: store.select(selectUserLoading),
    toast$: store.select(selectToast),
  };
};
